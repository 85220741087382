import React from "react";
import Layout from "../../layouts/Layout";
import { graphql } from "gatsby";
import { Col, Container, Row } from "react-bootstrap";
import Tags from "../../components/Tags";
import LinkFinder from "../../components/LinkFinder";
import Hint from "../../components/Hint";
import JoinTheProgram from "../../components/JoinTheProgram";
import SocialShare from "../../components/SocialShare";
import AmazonCategories from "../../components/AmazonCategories";

const TipsSpecialChild = ({ pageContext, data, location }) => {
  const page = data.page;
  const allChildPages = data.TipsChildPages.edges;
  const bestsellerCategories = data.page.acf && data.page.acf.tips_bestseller;
  const categories = data.page.acf && data.page.acf.tips_categories;
  const hint = data?.hint?.hint;
  const content = data?.content?.content;

  const linkFinderCategories = data.LinkFinderCategories.edges;
  const linkFinderLinks = data.LinkFinderLinks.edges;

  const stringTranslations = pageContext.stringTranslations;

  return (
    <Layout
      context={pageContext}
      useContainer={false}
      location={location}
      className="tips-special-page"
      progressBarTarget="article"
    >
      <article>
        <Container className="tips-child-page__header">
          <Row>
            <Col
              lg={{ span: 5, offset: 1 }}
              className="d-flex flex-column justify-content-center align-items-start mb-5"
            >
              <p className="roofline">Tips & Tricks</p>{" "}
              {/* TODO Where does tips & tricks come from? Could be from parent page title but that is different in design */}
              <h1 dangerouslySetInnerHTML={{ __html: page.post_title }} />
              {(page?.acf?.excerpt || page.post_excerpt) && (
                <div
                  className="intro"
                  dangerouslySetInnerHTML={{
                    __html: page?.acf?.excerpt || page.post_excerpt
                  }}
                />
              )}
            </Col>
            {page.featured_media && (
              <Col lg={5}>
                <img
                  srcSet={page.featured_media?.localFile?.childImageSharp?.fluid?.srcSet}
                  src={page.featured_media?.localFile?.childImageSharp?.fluid?.src}
                  alt={page.post_title}
                />
              </Col>
            )}
          </Row>
        </Container>
        <Container>
          <Row>
            <Col
              lg={{ span: 6, offset: 1 }}
              className="content"
              dangerouslySetInnerHTML={{ __html: content }}
            />
            <Col lg={{ span: 3, offset: 1 }}>
              <LinkFinder
                categories={linkFinderCategories}
                links={linkFinderLinks}
                desc={stringTranslations["Get links to all important landing pages on Amazon.co.uk"]}
                title={stringTranslations["Link Finder"]}
                linkText={stringTranslations["Choose Link"]}
                categoryText={stringTranslations["Choose Category"]}
              />
              {hint && (
                <Hint title={stringTranslations["Hint"]}>{hint}</Hint>
              )}
            </Col>
          </Row>

          {categories && categories.categories.length > 0 && (
            <Row>
              <Col lg={{ span: 6, offset: 1 }}>
                <Tags
                  title={categories.headline}
                  items={categories.categories}
                />
              </Col>
            </Row>
          )}
          {bestsellerCategories && bestsellerCategories.categories.length > 0 && (
            <Row>
              <Col lg={{ span: 6, offset: 1 }}>
                <Tags
                  title={bestsellerCategories.headline}
                  items={bestsellerCategories.categories}
                />
              </Col>
            </Row>
          )}

          <Row>
            <Col lg={{ span: 6, offset: 1 }} style={{ zIndex: 0 }}>
              <JoinTheProgram
                link={pageContext.signUpLink}
                variant="big"
                title={stringTranslations["Join the associates programme"]}
                benefit={stringTranslations["Earn up to 12% in commission income with a trusted e-commerce-leader"]}
                btnText={stringTranslations["Sign up"]}
              />
            </Col>
          </Row>

          <Row>
            <Col lg={{ span: 6, offset: 1 }}>
              <SocialShare
                likeCount={2}
                postId={page.wordpress_id}
                path={page.path}
              />
            </Col>
          </Row>
        </Container>
      </article>

      <AmazonCategories
        title={stringTranslations["Amazon Categories"]}
        categories={allChildPages}
      />
    </Layout>
  );
};

export const query = graphql`
    query TipsSpecialQuery($id: Int!, $locale: String!, $languageCode: String) {
        page: wordpressWpTips(wordpress_id: { eq: $id }) {
            post_title
            post_excerpt
            wordpress_id
            featured_media {
                localFile {
                    childImageSharp {
                        fluid(jpegQuality: 100, maxWidth: 980) {
                            srcSet
                            src
                        }
                    }
                }
            }
            acf {
                excerpt
            }
            path
            post_content
        }
        content: wordpressWpAmazonSpecials(language: {eq: $languageCode}) {
            content
        }
        TipsChildPages: allWordpressWpTips(
            filter: { language: { locale: { eq: $locale } } }
        ) {
            edges {
                node {
                    path
                    post_title
                    acf {
                        icon {
                            url {
                                localFile {
                                    publicURL
                                }
                            }
                        }
                    }
                }
            }
        }
        LinkFinderCategories: allWordpressWpLinkFinderCategories(
            filter: { language: { locale: { eq: $locale } } }
        ) {
            edges {
                node {
                    title
                    slug
                }
            }
        }
        LinkFinderLinks: allWordpressWpLinkFinderLinks(
            filter: { language: { locale: { eq: $locale } } }
        ) {
            edges {
                node {
                    url
                    title
                    parent_slug
                    parent_title
                }
            }
        }
        hint: wordpressWpTipsHint(language: {locale: {eq: $locale}}) {
            hint
        }
    }
`;

export default TipsSpecialChild;
